import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, isFunction } from 'lodash';

import Tooltip from './Tooltip';
import { withFeatureFlags } from 'components/context/withFeatureFlags';
import { getAbsoluteStaticUrl } from 'utils/path';
import { analyticAction } from 'ducks/analytic';
import { CybersourceKey } from '../const';
import { mapError } from '../util';

const errorMessages = {
  'card-holder': `Your card holder name is incomplete.`,
  'card-number': 'We are unable to accept this card.',
  'card-expiry': `Your card's expiration date is incorrect.`,
  'card-cvv': `Your card's security code is incomplete.`,
};

function Form({
  getFeatureFlags,
  context,
  onValidate,
  fingerprintScriptLoadedTimestamp,
  onTokenHandler,
  onFormErrorHandler,
  onTokenErrorHandler,
  getToken,
  isDisabled,
}) {
  const dispatch = useDispatch();
  const allowedCardTypes = getFeatureFlags(`FEATURE_ALLOWED_CARD_TYPES`) || [];

  const initFieldValidateStatus = {
    value: '',
    isStarted: false,
    isValid: false,
    error: '',
    showError: false,
  };

  const [formDisabled, setFormDisabled] = useState(false);
  const disabled = isDisabled || formDisabled;
  const [fieldsValidateStatus, setFieldsValidateStatus] = useState({
    'card-holder': initFieldValidateStatus,
    'card-number': initFieldValidateStatus,
    'card-expiry': initFieldValidateStatus,
    'card-cvv': initFieldValidateStatus,
  });

  const [isFieldsValidated, setIsFieldsValidated] = useState(false);

  const initCurrentCard = { name: '', securityCodeName: 'CVV' };
  const [currentCard, setCurrentCard] = useState(initCurrentCard);
  const [microform, setMicroform] = useState(false);

  const onFormError = (error) => {
    isFunction(onFormErrorHandler) && onFormErrorHandler(error);

    dispatch(
      analyticAction({
        eventName: 'Checkout Form Loaded Failure',
        source: 'microform form',
      }),
    );
  };

  const onTokenError = (error) => {
    isFunction(onTokenErrorHandler) &&
      onTokenErrorHandler({ message: mapError(5) });

    dispatch(
      analyticAction({
        eventName: 'Checkout Form Token Failure',
        source: error,
      }),
    );
  };

  // Initialize Form
  useEffect(() => {
    const myStyles = {
      input: {
        'font-size': '16px',
        'font-family': 'MarkPro, Arial, Helvetica, sans-serif',
        color: '#ffffff',
      },
    };

    try {
      // eslint-disable-next-line no-undef
      if (typeof Flex === 'function') {
        // eslint-disable-next-line no-undef
        const flex = new Flex(context);
        const microform = flex.microform({ styles: myStyles });

        const cardNumber = microform.createField('number', {
          placeholder: '0000 0000 0000 0000',
        });
        cardNumber.load('#card-number-container');
        cardNumber.on('change', cardNumberChangeHandler);
        cardNumber.on('blur', () => fieldBlurHandler('card-number'));

        const securityCode = microform.createField('securityCode', {
          placeholder: 'CVV',
        });
        securityCode.load('#securityCode-container');
        securityCode.on('change', securityCodeChangeHandler);
        securityCode.on('blur', () => fieldBlurHandler('card-cvv'));

        setMicroform(microform);

        dispatch(
          analyticAction({
            eventName: 'Checkout Form Loaded Success',
            source: CybersourceKey,
          }),
        );
      } else {
        onFormError({ message: mapError(4) });
      }
    } catch (error) {
      onFormError({ message: mapError(4) });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // getTokenHandler triggered by getToken prop
  useEffect(() => {
    if (getToken) getTokenHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getToken]);

  // to call onValidate only if validate status(isFieldsValidated) is updated
  useEffect(() => {
    isFunction(onValidate) && onValidate(isFieldsValidated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFieldsValidated]);

  // check all fields are validated
  useEffect(() => {
    const isAnyFieldFalse = Object.values(fieldsValidateStatus).some(
      (field) => field?.isValid === false,
    );
    isFieldsValidated && isAnyFieldFalse && setIsFieldsValidated(false);
    !isFieldsValidated && !isAnyFieldFalse && setIsFieldsValidated(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsValidateStatus]);

  const updateFieldValueStatus = (field, value) => {
    setFieldsValidateStatus((prevState) => {
      const prevFieldState = prevState[field] || {};
      return {
        ...prevState,
        [field]: {
          ...prevFieldState,
          value,
        },
      };
    });
  };

  const updateFieldValidateStatus = (field, valid) => {
    setFieldsValidateStatus((prevState) => {
      const prevFieldState = prevState[field] || {};
      return {
        ...prevState,
        [field]: {
          ...prevFieldState,
          isStarted: true,
          isValid: valid,
          ...{ error: valid ? '' : errorMessages[field] },
        },
      };
    });
  };

  const updateFieldShowErrorStatus = (field) => {
    setFieldsValidateStatus((prevState) => {
      const prevFieldState = prevState[field] || {};
      return {
        ...prevState,
        [field]: {
          ...prevFieldState,
          showError: prevFieldState.isStarted,
        },
      };
    });
  };

  const fieldBlurHandler = (field) => {
    updateFieldShowErrorStatus(field);
  };

  const getTokenHandler = () => {
    if (!microform) return false;

    setFormDisabled(true);

    const expiryDateField = fieldsValidateStatus['card-expiry'] || {};
    const expiryDate = expiryDateField?.value || '';
    const [month, year] = expiryDate.split(' / ');
    const options = {
      expirationMonth: month,
      expirationYear: `20${year}`,
    };

    microform.createToken(options, async function(error, token) {
      if (error) {
        onTokenError(error);
        setFormDisabled(false);
      } else {
        // Cybersource: To allow device profiling time to complete, ensure that 3 to 5 seconds elapse between the execution of the profiling code and when your customers submit their orders.
        const timeElapsed = Date.now() - fingerprintScriptLoadedTimestamp;
        if (timeElapsed < 5000) {
          await new Promise((resolve) =>
            setTimeout(resolve, 5000 - timeElapsed),
          );
        }

        onTokenHandler(token);

        dispatch(
          analyticAction({
            eventName: 'Checkout Form Token Success',
            source: CybersourceKey,
          }),
        );
      }
    });
  };

  const cardHolderInputHandler = (e) => {
    const cardHolder = e?.target?.value || '';

    updateFieldValidateStatus('card-holder', cardHolder?.trim() !== '');

    updateFieldValueStatus('card-holder', cardHolder);
  };

  const cardNumberChangeHandler = (event) => {
    const { valid, card } = event || {};
    updateFieldValidateStatus('card-number', valid);
    if (card && card.length) {
      const enteredCard = card[0];
      const { name: enteredCardName, securityCode: enteredCardSecurityCode } =
        enteredCard || {};
      const { name: enteredCardSecurityCodeName } =
        enteredCardSecurityCode || {};
      setCurrentCard({
        name: enteredCardName || initCurrentCard.name,
        securityCodeName:
          enteredCardSecurityCodeName || initCurrentCard.securityCodeName,
      });
    } else {
      setCurrentCard(initCurrentCard);
    }
  };

  const expirationDateInputHandler = (e) => {
    let returnValue = e?.target?.value || '';

    returnValue = returnValue.replace(/\D/g, '');

    const month = returnValue.substring(0, 2);
    const year = returnValue.substring(2, 4);

    if (returnValue.length > 2) {
      returnValue = month + ' / ' + year;
    }

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear() % 100;

    let isValid = true;

    if (
      parseInt(month) < 1 ||
      parseInt(month) > 12 || // check month 1~12
      parseInt(year) < currentYear || // check if past year
      (parseInt(year) === currentYear && parseInt(month) < currentMonth) // check if past month / year
    ) {
      isValid = false;
    }

    updateFieldValidateStatus('card-expiry', isValid);

    updateFieldValueStatus('card-expiry', returnValue);
  };

  const securityCodeChangeHandler = (event) => {
    const { valid } = event || {};
    updateFieldValidateStatus('card-cvv', valid);
  };

  // util to return if currentCard is existing from allowedCardTypes
  const isCurrentCard = allowedCardTypes.indexOf(currentCard?.name) !== -1;

  // util to check if a field is invalid
  const getFieldBorderClassname = (field) => {
    const fieldValidateStatus =
      (fieldsValidateStatus && fieldsValidateStatus[field]) || {};

    return fieldValidateStatus &&
      fieldValidateStatus.showError &&
      !fieldValidateStatus.isValid
      ? 'border-error-red'
      : 'border-light-grey';
  };

  return (
    <>
      <div className='container card'>
        <div className='card-body'>
          <div id='errors-output' role='alert'></div>

          <input type='hidden' id='flexresponse' name='flexresponse' />
        </div>
      </div>

      <div className={`new-credit-card-section`}>
        <div className='mb-24'>
          <label
            htmlFor='card-holder'
            className='text-white text-sm font-MarkProBold md:text-left mt-4 block'
          >
            Name on card
          </label>
          <input
            id='card-holder'
            className={`font-MarkPro bg-dark-grey text-white px-16 h-48 focus:outline-none w-full border-2 rounded xs:mt-8 ${getFieldBorderClassname(
              'card-holder',
            )}`}
            type='text'
            placeholder='John Smith'
            value={fieldsValidateStatus['card-holder']?.value}
            onChange={cardHolderInputHandler}
            onBlur={() => fieldBlurHandler('card-holder')}
            disabled={disabled}
            autoComplete='off'
          />
          <LabelFieldError
            fieldsValidateStatus={fieldsValidateStatus}
            field='card-holder'
          />
        </div>
        <div className='relative flex flex-col mb-24'>
          <div className='flex items-end'>
            <label className='text-white text-sm font-MarkProBold md:text-left flex-grow'>
              Card number
            </label>
            <div
              id='card-type-group'
              className={`inline-flex relative os-top-8`}
            >
              {allowedCardTypes.map((brand) => (
                <ImgCardLogo key={`ImgCardLogo-${brand}`} brand={brand} />
              ))}
            </div>
            {isCurrentCard && (
              <div className='absolute pin-r mt-48 pin-t mr-16'>
                <ImgCardLogo brand={currentCard.name} />
              </div>
            )}
          </div>
          <div
            id='card-number-container'
            className={`card-field bg-dark-grey rounded xs:mt-8 pl-16 ${getFieldBorderClassname(
              'card-number',
            )}`}
          />
          <LabelFieldError
            fieldsValidateStatus={fieldsValidateStatus}
            field='card-number'
          />
        </div>
        <div className='flex flex-1 justify-between mb-16'>
          <div className='flex flex-col w-49p'>
            <label
              htmlFor='card-expiry'
              className='text-white text-sm font-MarkProBold md:text-left mt-4'
            >
              Expiration date
            </label>
            <input
              id='card-expiry'
              className={`font-MarkPro bg-dark-grey text-white px-16 h-48 focus:outline-none w-full border-2 rounded xs:mt-8 ${getFieldBorderClassname(
                'card-expiry',
              )}`}
              type='text'
              placeholder='MM / YY'
              value={fieldsValidateStatus['card-expiry']?.value}
              onChange={expirationDateInputHandler}
              onBlur={() => fieldBlurHandler('card-expiry')}
              disabled={disabled}
              maxLength={7}
              autoComplete='off'
            />
            <LabelFieldError
              fieldsValidateStatus={fieldsValidateStatus}
              field='card-expiry'
            />
          </div>
          <div className='flex flex-col w-49p relative'>
            <label
              fontVariant='secondary'
              className='text-sm mt-4 font-MarkProBold os-transition os-transitionproperty-all text-left'
              htmlFor='card-cvv'
              id='card-cvv-error'
            >
              Security Code
            </label>
            <div className='relative'>
              <div
                id='securityCode-container'
                className={` card-field bg-dark-grey rounded xs:mt-8 pl-16 ${getFieldBorderClassname(
                  'card-cvv',
                )}`}
              />
              <Tooltip allowedCardTypes={allowedCardTypes} />
            </div>
            <LabelFieldError
              fieldsValidateStatus={fieldsValidateStatus}
              field='card-cvv'
            />
          </div>
        </div>
      </div>
    </>
  );
}

function ImgCardLogo({ brand }) {
  if (isEmpty(brand)) return <></>;

  return (
    <img
      className={`h-32 w-auto`}
      src={getAbsoluteStaticUrl(`/images/cards/${brand}.svg`)}
      alt={brand}
      key={brand}
    />
  );
}

function LabelFieldError({ fieldsValidateStatus, field }) {
  const fieldValidateStatus =
    fieldsValidateStatus && fieldsValidateStatus[field];

  if (!fieldValidateStatus) return <></>;

  return (
    fieldValidateStatus.showError &&
    fieldValidateStatus.error && (
      <label className='text-error-red font-bold text-sm mt-8'>
        {fieldValidateStatus.error}
      </label>
    )
  );
}

export default withFeatureFlags(Form);
