import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Score from './Score';
import defaultConfig from '../../../../constants/defaultConfig';

const baseImgResizeUrl = _.get(defaultConfig, 'baseImgResizerUrl', null);

const renderScore = (score, leading, showScore) => <Score leading={leading} score={score} showScore={showScore} />;

const renderTeamName = teamShortCode => <span className="font-MarkPro text-xs leading-loose text-white ml-4">{teamShortCode}</span>;

const renderLogo = imgUrl => {
  // Temp. Workaround for image resizer
  const payload = {
    key: imgUrl,
    edits: {
      resize: {
        width: 16,
        height: 16,
      },
    },
  };
  const imgUrlBase64 = Buffer.from(JSON.stringify(payload)).toString('base64');

  return (
    <img
      className={`
        w-16
        h-16
        object-contain
        flex-no-grow
        flex-no-shrink
        ml-14
      `}
      alt=""
      src={baseImgResizeUrl && imgUrlBase64 ? `${baseImgResizeUrl}/${imgUrlBase64}` : imgUrl}
    />
  );
};

const Team = ({imgUrl, teamShortCode, score, leading, showScore}) => (
  <div className="flex flex-row items-center relative w-135">
    { renderLogo(imgUrl) }
    { renderTeamName(teamShortCode) }
    { renderScore(score, leading, showScore)}
  </div>
);

Team.propTypes = {
  /** URL to the team icon to be used. */
  imgUrl: PropTypes.string.isRequired,
  /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
  className: PropTypes.string,
  /** Team shortCode. */
  teamShortCode: PropTypes.string.isRequired,
  /** Score */
  score: PropTypes.number.isRequired,
  /** Leading or not */
  leading: PropTypes.bool.isRequired,
  /** Show score option */
  showScore: PropTypes.bool.isRequired
};

Team.defaultProps = {
  className: null,
  score: 0,
  leading: false,
  showScore: false
};

export default Team;
