import React from 'react';

import Banner from './layouts/Banner';
import DynamicRail from './DynamicRail';

function getCustomWidget({ carousel, getCarouselLayoutParams }) {
  const assetId = carousel?.assetId;
  switch (carousel.type) {
    case 'banner':
      return <Banner assetId={assetId} key={assetId} />;

    case 'dynamic_rail':
      return (
        <DynamicRail
          assetId={assetId}
          key={assetId}
          getCarouselLayoutParams={getCarouselLayoutParams}
        />
      );

    default:
      return <></>;
  }
}

export default getCustomWidget;
