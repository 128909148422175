import React from 'react';
import styled from 'styled-components';

import Picture from '../../Picture';
import ButtonLayout from '../components/ButtonLayout';
import Link from '../components/Link';
import ActionComponent from '../components/ActionComponent';
import defaultConfig from 'constants/defaultConfig';

const { sizesAspectRatio } = defaultConfig?.image || {};

const BannerWrapper = styled.div`
  @media (min-width: 768px) {
    padding-top: ${({ aspectRatio }) =>
      (aspectRatio && sizesAspectRatio && sizesAspectRatio[aspectRatio]?.pct) ||
      '39.3506%'};
  }
`;

const BannerImage = styled.div`
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  position: relative;
  z-index: 0;
  @media (min-width: 768px) {
    position: absolute;
    border-radius: 16px;
  }
  @media (min-width: 992px) {
    border-radius: 24px;
  }
  img {
    display: block;
  }
`;

const BannerText = styled.div`
  border-radius: 0 0 16px 16px;
  overflow:hidden;
  padding-top:16px;
  padding-bottom:24px;
  background-color:#222;
  text-align:center;
  .title{
    font-size:24px;
    line-height:32px;
    margin-bottom:8px;
    @media(min-width:992px){
      font-size:32px;
      line-height:40px;
      margin-bottom:16px;
    }
  }
  .description{
    font-size:16px;
    line-height:24px;
    @media(min-width:992px){
      font-size:20px;
      line-height:32px;
    }
  }
  .banner-text-inner{
    &:after{
      content:'';
      display:block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      border: 2px solid rgba(255, 255, 255, 0.33);
      @media (min-width: 768px) {
        display:none;
      }
    }
  }
  @media (min-width: 768px) {
    text-align:left;
    border-radius:16px;
    background-color:transparent;
    &:before{
      content:'';
      display:block;
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background-color:transparent;
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, #000 100%);
      opacity:67%;
    }
    &:after{
      content:'';
      display:block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      border: 2px solid rgba(255, 255, 255, 0.33);
      z-index:-1;
      @media(min-width:992px){
        border-radius: 24px;
        border-width:4px;
      }
    }
  }
  @media (min-width: 992px) {
    border-radius: 24px;
  }
  .article-detail &{
    @media(min-width:992px){
      .title{
        font-size:24px;
        line-height:32px;
        margin-bottom:8px;
      }
      .description{
        font-size:16px;
        line-height:24px;
      }
      .banner-text-inner > div{
        padding-left: 1.5rem;
      }
    }
  }
}`;

const BannerBadge = styled.div`
  right: 24px;
  top: -12px;
`;

function BannerUI({
  asset,
  actionHandlers,
  defaultImageAspectRatio,
  imgSrc,
  srcSet,
}) {
  const { title, description, contentCard } = asset;

  const { badgeLabel, noTextOverlay, actions: { cardClickAction } = {} } =
    contentCard || {};

  const {
    handleCardClick,
    handlePrimaryButtonClickedClick,
    handleSecondaryButtonClickedClick,
  } = actionHandlers;

  return (
    <>
      <ActionComponent />
      <Link action={cardClickAction} onClick={handleCardClick}>
        <div className='relative w-full mt-32 contentcard-banner'>
          {badgeLabel && (
            <BannerBadge className='text-black bg-yellow rounded-xl shadow-sm absolute z-2 px-12 py-2 md:py-4'>
              <div className='leading-base text-xs md:text-sm font-MarkProBold'>
                {badgeLabel}
              </div>
            </BannerBadge>
          )}
          <BannerWrapper
            className='relative overflow-hidden'
            aspectRatio={defaultImageAspectRatio}
          >
            <BannerImage className='md:absolute pin z-1'>
              <Picture
                alt={title}
                src={imgSrc}
                srcSet={srcSet}
                className={'h-full w-full object-cover'}
              />
            </BannerImage>
            <BannerText className='md:absolute pin z-1'>
              <div className='banner-text-inner block md:flex items-center h-full text-white w-full md:w-1/2'>
                <div className='w-full relative z-1 px-16 sm:px-24 md:pr-0 lg:pl-32'>
                  {!noTextOverlay && (
                    <>
                      <div className='title font-MarkProHeavy'>{title}</div>
                      <div className='description font-MarkPro mb-24'>
                        {description}
                      </div>
                    </>
                  )}

                  <ButtonLayout
                    contentCard={contentCard}
                    onPrimaryButtonClick={handlePrimaryButtonClickedClick}
                    onSecondaryButtonClick={handleSecondaryButtonClickedClick}
                  />
                </div>
              </div>
            </BannerText>
          </BannerWrapper>
        </div>
      </Link>
    </>
  );
}

export default BannerUI;
