
const voucherConfig = {
  landingPageText: ' Redeem your access code',
  content: `
    <p>
      Vestibulum et leo luctus, vestibulum nibh ac, luctus purus. Nulla dapibus mauris tellus. Nam eu diam nibh. Phasellus at sollicitudin lorem. 
      Aenean id interdum enim. Pellentesque sagittis eu dolor id rutrum. Vestibulum ac turpis a libero ultrices tincidunt. Donec id mi commodo, condimentum neque in, congue elit.
    </p>
    <p>
      Praesent scelerisque dui vel euismod accumsan. Proin mattis condimentum dolor et pretium. Pellentesque at posuere massa, sed bibendum odio. Curabitur tristique eget mauris eu vulputate. Sed vel nunc interdum, congue dolor a, euismod purus. Phasellus malesuada odio at hendrerit semper. Integer a pulvinar urna. In scelerisque orci sed hendrerit porttitor. Curabitur auctor justo magna, in vulputate diam gravida eu. Aenean commodo nisi ut augue rhoncus, ut egestas nisl interdum. Donec feugiat arcu id accumsan convallis. Suspendisse vel ante venenatis turpis lacinia vestibulum sed vitae magna.
    </p>
    <p>
      Vestibulum et leo luctus, vestibulum nibh ac, luctus purus. Nulla dapibus mauris tellus. Nam eu diam nibh. Phasellus at sollicitudin lorem. Aenean id interdum enim. Pellentesque sagittis eu dolor id rutrum. Vestibulum ac turpis a libero ultrices tincidunt. Donec id mi commodo, condimentum neque in, congue elit.
      Praesent scelerisque dui vel euismod accumsan. Proin mattis condimentum dolor et pretium. Pellentesque at posuere massa, sed bibendum odio. Curabitur tristique eget mauris eu vulputate. Sed vel nunc interdum, congue dolor a, euismod purus. Phasellus malesuada odio at hendrerit semper. Integer a pulvinar urna. In scelerisque orci sed hendrerit porttitor. Curabitur auctor justo magna, in vulputate diam gravida eu. Aenean commodo nisi ut augue rhoncus, ut egestas nisl interdum. Donec feugiat arcu id accumsan convallis. Suspendisse vel ante venenatis turpis lacinia vestibulum sed vitae magna.
    </p>
    `,
  required: true,
  checkBoxText: 'I agree to the campaign voucher offer',
  successTitle: 'Welcome',
  successContent: 'You have successfully redeemed your voucher and your Optus Sport account is now active and ready to go.',
  errorContent: 'This offer is available for new accounts only.',
  successPageBackgroundImage: '',
  successPageMainImage1X: '',
  successPageMainImage2X: ''
};

export default voucherConfig;
