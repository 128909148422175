import React from 'react';
import { useState } from 'react';
import { isFunction } from 'lodash';

import LoadScript from './components/LoadScript';
import Form from './components/Form';
import { CybersourceKey } from './const';

function CybersourceContainer({
  paymentSessionsData,
  onToken,
  getToken,
  onValidate,
}) {
  const [hasFormError, setHasFormError] = useState(false);
  const [isScriptsReady, setIsScriptReady] = useState(false);
  const [
    fingerprintScriptLoadedTimestamp,
    setFingerprintScriptLoadedTimestamp,
  ] = useState(false);

  const { context, sessionId } = paymentSessionsData || {};

  const scriptOnLoadHandler = (timestamp) => {
    setFingerprintScriptLoadedTimestamp(timestamp);
    setIsScriptReady(true);
  };

  const scriptOnErrorHandler = (error) => {
    onTokenErrorHandler(error);
    setHasFormError(true);
  };

  const onTokenHandler = (token) => {
    const error = token?.error;
    isFunction(onToken) &&
      onToken({
        sessionId,
        token: { token, source: CybersourceKey },
        ...(error && { error }),
      });
  };

  const onFormErrorHandler = (error) => {
    onTokenErrorHandler(error);
    setHasFormError(true);
  };

  const onTokenErrorHandler = (error) => {
    onTokenHandler({ error: { message: error?.message } });
    setHasFormError(false);
  };

  return (
    <>
      {/* to use fingerprintScriptLoadedTimestamp as a flag if the script is loaded */}
      {!fingerprintScriptLoadedTimestamp && (
        <LoadScript
          paymentSessionsData={paymentSessionsData}
          onLoaded={scriptOnLoadHandler}
          onError={scriptOnErrorHandler}
        />
      )}
      {isScriptsReady && (
        <Form
          context={context}
          onValidate={onValidate}
          fingerprintScriptLoadedTimestamp={fingerprintScriptLoadedTimestamp}
          onTokenHandler={onTokenHandler}
          onFormErrorHandler={onFormErrorHandler}
          onTokenErrorHandler={onTokenErrorHandler}
          getToken={getToken}
          isDisabled={hasFormError}
        />
      )}
    </>
  );
}

export default CybersourceContainer;
