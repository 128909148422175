import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPaymentSessions,
  getPaymentSessionsReset,
} from 'actions/paymentSessions';
import Loader from 'common/Loader';
import CybersourceContainer from 'lib/cybersource';
import { isFunction } from 'lodash';

function CardDetailsInputV2({ onValidate, getToken, onToken }) {
  const dispatch = useDispatch();
  const { paymentSessions } = useSelector((state) => ({
    paymentSessions: state?.paymentSessions,
  }));

  useEffect(() => {
    const { inProgress } = paymentSessions || {};
    // to prevent getPaymentSessions being called multiple times by the parent component re-rendering it
    !inProgress && dispatch(getPaymentSessions());
    return () => {
      // reset payment sessions reducer
      // to prevent passing old session data to child component
      // it causes re-rendering children component with old data
      dispatch(getPaymentSessionsReset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { isReady, isSuccess, error } = paymentSessions || {};
    if (isReady && !isSuccess && error) {
      // If paymentSessions returns error, pass it to parent component
      // so the error can be hanlded its parent component
      isFunction(onTokenHandler) && onTokenHandler({ error });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSessions]);

  const onTokenHandler = (token) => {
    onToken(token);
  };

  const { inProgress, isSuccess, data: paymentSessionsData } =
    paymentSessions || {};

  if (inProgress) return <Loader />;

  if (isSuccess) {
    return (
      <>
        <CybersourceContainer
          paymentSessionsData={paymentSessionsData}
          getToken={getToken}
          onToken={onTokenHandler}
          onValidate={onValidate}
        />
      </>
    );
  }

  return <></>;
}

export default React.memo(CardDetailsInputV2);
